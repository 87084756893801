
import { Component, Vue } from 'vue-property-decorator'
import BargeService from './services/barge-service'
import String from '@/mixins/string-mixins'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import { AppModule } from '@/store/modules/AppModule'
import { AuthModule } from '@/store/modules/AuthModule'

  @Component({
    name: 'Vessel',
    components: {
      // Notify
    }
  })
export default class Vessel extends Vue {
    private tabIndex = 1
    private isEdit = false
    private barges: any = []

    private barge: any = {
      id: null,
      code: null,
      name: null,
      imo_no: null,
      length_overall: null,
      uom: null,
      capacity: null,
      uos: null,
      yom: null,
      class: null,
      flag: null,
      other: null
      // modified_user: AuthModule.user.fullname
    }

    created () {
      this.populateBargeData()
    }

    private async populateBargeData () {
      const barges = await BargeService.getBarges()
      this.barges = barges.data
    }

    private async createVesselCode () {
      if (this.barge.name === '' || this.barge.yom.length < 4) {
        this.barge.code = ''
        return null
      }
      const runningNumber = await BargeService.getBargeCode()
      this.barge.code = `${String.strByWords(this.barge.name)}/${this.barge.yom}/${runningNumber.data}`
    }

    private save () {
      if (this.isEdit) {
        this.update()
      } else {
        this.create()
      }
    }

    private create () {
      const barge = this.barge
      barge.created_user = AuthModule.user.fullname
      barge.modified_user = AuthModule.user.fullname
      BargeService.create(barge).then((response: any) => {
        ToastModule.message(response.data.message)
        this.cancel()
        this.populateBargeData()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
        console.log(error)
      })
    }

    private async edit (id: number) {
      this.isEdit = true
      this.tabIndex++
      const response = await BargeService.getBargeData(id)
      this.barge = response.data
    }

    private update () {
      const barge = this.barge
      barge.modified_user = AuthModule.user.fullname
      BargeService.update(barge).then((response: any) => {
        ToastModule.message(response.data.message)
        this.cancel()
        this.populateBargeData()
      }).catch(error => {
        ToastModule.message(error.response.data.message)
        console.log(error)
      })
    }

    private cancel () {
      this.isEdit = false
      this.reset(this.barge)
      this.tabIndex--
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}
