import APIService from '@/services/api-service'
const resource = '/barge'

class BargeService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getBarges () {
    return this.connector.get(`${resource}/details`)
  }

  public getBargeData (id: number) {
    return this.connector.get(`${resource}/details/${id}`)
  }

  public getBargeCode () {
    return this.connector.get(`${resource}/code`)
  }

  public create (data: any) {
    return this.connector.post(`${resource}/create`, data)
  }

  public update (data: any) {
    return this.connector.put(`${resource}/update/${data.id}`, data)
  }
}

export default new BargeService()
