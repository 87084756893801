class StringHelper {
    protected charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    protected result: any = null

    public alpha (length: number) {
      for (let i = length; i > 0; --i) this.result += this.charset[Math.floor(Math.random() * this.charset.length)]
      return this.result
    }

    public numeric (min: number, max: number) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    public alphanum () {
      return this.alpha(5) + this.numeric(1, 9999999)
    }

    public rand (prefix: string, maxmiumNumbers: any) {
      return prefix + this.alpha(2) + this.numeric(1, maxmiumNumbers)
    }

    public strByWords (str: string) {
      const temp: any = []
      str.split(' ').map(word => word.substring(0, 2) !== '' ? temp.push(word.substring(0, 2)) : '')
      this.result = temp.join('')
      return this.result.toUpperCase()
    }
}

export default new StringHelper()
